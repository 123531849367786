import { useInfiniteQuery, useMutation, useQueries, useQuery } from '@tanstack/react-query'
import snakeCase from 'lodash/snakeCase'

import { ApiPaginatedEntityResponse } from 'src/action-creators'
import { API_DEFAULT_LIMIT, API_URL } from 'src/constants/constants'
import { useMembershipContext } from 'src/contexts/MembershipContext'
import { Membership } from 'src/types'
import { fetchBody, getRequestInit } from 'src/utils'
import { transformObjectToQuery } from 'src/utils/query/query'

import * as T from './entity.types'

// Copied here from userContext to prevent import cycles
interface UseCurrentMembershipArgs<T extends boolean> {
  optional?: T
}

type UseCurrentMembershipResult<T> = T extends true ? Membership | undefined : Membership

const useCurrentMembership = <T extends boolean = false>({
  optional,
}: UseCurrentMembershipArgs<T> = {}): UseCurrentMembershipResult<T> => {
  const membershipContext = useMembershipContext({ optional })

  return membershipContext?.currentMembership as Membership
}

export const getEntityById = <T, QueryType = {}>({
  currentMembershipId,
  endpoint,
  entityId,
  queryParams,
}: T.GetEntityByIdProps<QueryType>): Promise<T> => {
  const queryString = transformObjectToQuery({ ...queryParams })

  return entityId
    ? fetchBody(
        `${API_URL}/${endpoint}/${entityId}/${queryString}`,
        getRequestInit({ method: 'GET', currentMembershipId: currentMembershipId })
      )
    : Promise.reject(new Error('entityId is undefined'))
}

export const useEntityQuery = <Data, QueryParams = {}, TransformedData = Data>({
  endpoint,
  entityId,
  queryKey,
  queryParams,
  queryOptions,
}: T.UseEntityQueryProps<Data, QueryParams, TransformedData>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useQuery<Data, Error, TransformedData>(
    [queryKey, entityId, queryParams],
    () =>
      getEntityById<Data, QueryParams>({
        currentMembershipId: currentMembership?.id,
        endpoint,
        entityId,
        queryParams,
      }),
    {
      ...queryOptions,
      enabled: !!entityId && queryOptions?.enabled,
    }
  )
}

export const useEntityQueries = <T, QueryType = {}>({
  endpoint,
  entityIds = [],
  queryKey,
  queryParams,
  queryOptions,
}: T.UseEntityQueriesProps<QueryType>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useQueries({
    queries: entityIds.map(entityId => ({
      queryKey: [queryKey, entityId, queryParams],
      queryFn: () =>
        getEntityById<T, QueryType>({
          currentMembershipId: currentMembership?.id,
          endpoint,
          entityId,
          queryParams,
        }),
      ...queryOptions,
    })),
  })
}

export const getEntities = <T, QueryType>({
  currentMembershipId,
  endpoint,
  queryParams,
}: T.GetEntitiesProps<QueryType>): Promise<T> => {
  const queryString = transformObjectToQuery(queryParams || {})

  return fetchBody(
    `${API_URL}/${endpoint}/${queryString}`,
    getRequestInit({ method: 'GET', currentMembershipId })
  )
}

export const useEntitiesQuery = <T, QueryType = {}, TData = T>({
  contextualMembershipId,
  endpoint,
  queryKey,
  queryParams,
  queryOptions,
}: T.UseEntitiesQueryProps<T, QueryType, TData>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useQuery<T, Error, TData>(
    [queryKey, queryParams],
    () =>
      getEntities<T, QueryType>({
        currentMembershipId: contextualMembershipId || currentMembership?.id,
        endpoint,
        queryParams,
      }),
    queryOptions
  )
}

export const getPaginatedEntities = <T, QueryType>({
  currentMembershipId,
  endpoint,
  pageParam,
}: T.GetPaginatedEntitiesProps<QueryType>): Promise<ApiPaginatedEntityResponse<T>> => {
  if (pageParam?.url) {
    return fetchBody(pageParam.url, getRequestInit({ method: 'GET', currentMembershipId }))
  }

  const queryString = transformObjectToQuery({
    limit: API_DEFAULT_LIMIT,
    ...pageParam?.queryObject,
  })

  return fetchBody(
    `${API_URL}/${endpoint}/${queryString}`,
    getRequestInit({ method: 'GET', currentMembershipId })
  )
}

export const usePaginatedEntitiesQuery = <T, QueryType = {}, TData = T>({
  contextualMembershipId,
  endpoint,
  queryKey,
  queryParams,
  queryOptions,
}: T.UsePaginatedEntityQueryProps<T, QueryType, TData>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useInfiniteQuery<ApiPaginatedEntityResponse<T>, Error, ApiPaginatedEntityResponse<TData>>(
    [queryKey, queryParams],
    ({
      pageParam = { queryObject: queryParams },
    }: {
      pageParam?: T.PageParamResponse<QueryType>
    }) =>
      getPaginatedEntities<T, QueryType>({
        currentMembershipId: contextualMembershipId || currentMembership?.id,
        endpoint,
        pageParam,
      }),
    {
      ...queryOptions,
      getNextPageParam: params => (!!params.next ? { url: params.next } : undefined),
    }
  )
}

export const postEntity =
  <T, TResponse = T>({ contentType, currentMembershipId, endpoint }: T.PostEntityProps) =>
  (entity: T): Promise<TResponse> =>
    fetchBody(
      `${API_URL}/${endpoint}/`,
      getRequestInit({ method: 'POST', payload: entity, contentType, currentMembershipId })
    )

export const usePostEntityMutation = <T, TResponse = T, TContext = T>({
  endpoint,
  queryOptions,
  contentType,
}: T.UseEntityMutationProps<T, TResponse, TContext>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useMutation(
    postEntity<T, TResponse>({ currentMembershipId: currentMembership?.id, endpoint, contentType }),
    queryOptions
  )
}

export const putEntity =
  <T extends T.EntityWithId, TResponse = T>({
    endpoint,
    entityId,
    contentType,
    currentMembershipId,
  }: T.PutEntityProps) =>
  (entity: T): Promise<TResponse> => {
    const { id, ...payload } = entity

    return fetchBody<TResponse>(
      `${API_URL}/${endpoint}/${id ?? entityId}/`,
      getRequestInit({ method: 'PUT', payload, contentType, currentMembershipId })
    )
  }

export const usePutEntityMutation = <T extends T.EntityWithId, TResponse = T, TContext = T>({
  endpoint,
  entityId,
  queryOptions,
  contentType,
}: T.UseUpdateEntityMutationProps<T, TResponse, TContext>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useMutation(
    putEntity<T, TResponse>({
      endpoint,
      entityId,
      contentType,
      currentMembershipId: currentMembership?.id,
    }),
    queryOptions
  )
}

export const patchEntity =
  <T extends T.EntityWithId, TResponse = T>({
    endpoint,
    entityId,
    contentType,
    currentMembershipId,
  }: T.PatchEntityProps) =>
  (entity: Partial<T>): Promise<TResponse> => {
    const { id, ...payload } = entity

    return fetchBody(
      `${API_URL}/${endpoint}/${id ?? entityId}/`,
      getRequestInit({ method: 'PATCH', payload, contentType, currentMembershipId })
    )
  }

export const usePatchEntityMutation = <T extends T.EntityWithId, TResponse = T, TContext = T>({
  endpoint,
  entityId,
  queryOptions,
  contentType,
}: T.UseUpdateEntityMutationProps<T, TResponse, TContext>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useMutation(
    patchEntity<T, TResponse>({
      endpoint,
      entityId,
      contentType,
      currentMembershipId: currentMembership?.id,
    }),
    queryOptions
  )
}

export const deleteEntity =
  ({ currentMembershipId, endpoint }: T.DeleteEntityProps) =>
  (entityId: Id): Promise<undefined> =>
    fetchBody(
      `${API_URL}/${endpoint}/${entityId}/`,
      getRequestInit({ method: 'DELETE', currentMembershipId })
    )

export const useDeleteEntityMutation = <T, TContext = T>({
  endpoint,
  queryOptions,
}: T.UseDeleteEntityMutationProps<T, TContext>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useMutation<any, Error, any, TContext>(
    deleteEntity({ currentMembershipId: currentMembership?.id, endpoint }),
    queryOptions
  )
}

export const postAction =
  <T extends T.PayloadWithId, TResponse, QueryParams = {}>({
    action,
    contentType,
    currentMembershipId,
    endpoint,
    queryParams,
  }: T.PostActionProps<QueryParams>) =>
  (data: T): Promise<TResponse> => {
    const { id, ...payload } = data
    const queryString = transformObjectToQuery({ ...queryParams })

    return fetchBody(
      `${API_URL}/${endpoint}/${id}/${snakeCase(action)}/${queryString}`,
      getRequestInit({ method: 'POST', payload, contentType, currentMembershipId })
    )
  }

export const usePostAction = <T extends T.PayloadWithId, QueryParams, TResponse = T, TContext = T>({
  action,
  contentType,
  endpoint,
  queryOptions,
  queryParams,
}: T.UsePostActionProps<T, TResponse, QueryParams, TContext>) => {
  const currentMembership = useCurrentMembership({ optional: true })

  return useMutation(
    postAction<T, TResponse, QueryParams>({
      action,
      contentType,
      currentMembershipId: currentMembership?.id,
      endpoint,
      queryParams,
    }),
    queryOptions
  )
}
